import React from "react"
import { Container, Row } from "reactstrap"
import { Helmet } from "react-helmet"

import {
  already,
  Banner,
  BlockImageTextHalf,
  CodexItem,
  EdgeBorderFluid,
  LogoHeading,
  ReferencesNext,
  TitleH2Center,
  Button,
  ButtonStyle,
} from "@igloonet-web/shared-ui"
import { ContactForm, Layout } from "../components"

import IconChatLove from "../icons/chat-love.svg"
import IconRocket from "../icons/rocket.svg"
import IconSafe from "../icons/safe.svg"

import ImageDoktorKladivoWeb from "../images/vyvoj/doktor-kladivo-web.jpg"
import ImageRoyalCaninWeb from "../images/vyvoj/royal-canin-web.jpg"
import ImageSNBZWeb from "../images/vyvoj/snbz-web.jpg"

import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoDomyDnes from "../images/reference/domy-dnes/logo-domydnes.png"
import LogoPanvicky from "../images/reference/panvicky/logo-panvicky.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoRoyalCanin from "../images/reference/royal-canin/logo-rc.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"

const Vyvoj = (): React.ReactNode => {
  const vyvojReferences = [
    {
      href: "/vyvoj/reference/royal-canin",
      imageSrc: LogoRoyalCanin,
      imageAlt: "Royal Canin",
    },
    {
      href: "/vyvoj/reference/doktor-kladivo",
      imageSrc: LogoDoktorKladivo,
      imageAlt: "doktorkladivo.cz",
    },
    {
      href: "/vyvoj/reference/snowboard-zezula",
      imageSrc: LogoSZ,
      imageAlt: "snowboardzezula.cz",
    },
    {
      href: "/vyvoj/reference/panvicky",
      imageSrc: LogoPanvicky,
      imageAlt: "panvice.cz",
    },
    {
      href: "/vyvoj/reference/planetaher",
      imageSrc: LogoPlaneta,
      imageAlt: "planetaher.cz",
    },
    {
      href: "/vyvoj/reference/domy-dnes",
      imageSrc: LogoDomyDnes,
      imageAlt: "domy-dnes.cz",
    },
  ]

  return (
    <Layout>
      <Helmet>
        <title>Vývoj webů a webových aplikací | igloonet</title>
        <meta
          name="description"
          content="Věnujeme se tvorbě webových stránek a aplikací na míru.
            Dokážeme optimalizovat váš web směrem k větší efektivitě. Nezalekneme
            se ani rozsáhlejších webových projektů."
        />
      </Helmet>
      <Banner>
        <h1>Pomůžeme vám s příštím projektem</h1>
        <p>
          Máme zkušenosti s malými i velkými e-shopy, aplikacemi i portály pro
          náročné klienty. Postaráme se i o hosting, SEO, PPC a strategie pro
          sociální sítě. Rádi se ale pustíme i do něčeho úplně nového.
        </p>
        <p>
          Budeme spolehlivým parťákem u projektů, na které si ostatní
          netroufnou. Pokud váš projekt zatím nemá pevné obrysy a mantinely,
          pomůžeme vám s jejich definováním.
        </p>
        <Button to="#formular" style={ButtonStyle.Banner}>
          Chci řešení mimo krabici
        </Button>
      </Banner>

      <Container className="py-5">
        <TitleH2Center>Proč chtít řešení od nás</TitleH2Center>
        <EdgeBorderFluid>
          <Row>
            <CodexItem
              icon={<IconChatLove width={32} height={32} />}
              title="Vaše řešení, váš kód"
            >
              Chceme dlouhodobou spolupráci založenou na důvěře, ne vás držet
              jako rukojmí. Zdrojový kód tak bude kompletně váš.
            </CodexItem>
            <CodexItem
              icon={<IconRocket width={32} height={32} />}
              title="Žádné pocity, ale tvrdá data"
            >
              Uživatelský výzkum bereme vážně. Přesvědčíme vás, že bez
              důkladného pochopení vašich zákazníků a jejich potřeb, nemá cenu s
              projektem začínat. Než se pustíme do vývoje, analyzujeme
              konkurenci, tvoříme persony a testujeme wireframy.
            </CodexItem>
            <CodexItem
              icon={<IconSafe width={32} height={32} />}
              title="Práce pod kontrolou"
            >
              Během celé spolupráce budete mít přístup do našeho projektového
              systému Redmine. V reálném čase můžete sledovat, na čem se zrovna
              pracuje.
            </CodexItem>
            <CodexItem
              icon={<IconChatLove width={32} height={32} />}
              title="Vývoj, hosting a marketing"
            >
              V igloonetu nemáme jen vývojáře. Vašemu projektu rádi zajistíme
              bezpečný hosting a navrhneme marketingovou strategii na míru. PPC,
              SEO nebo mailing pro nás nejsou problém.
            </CodexItem>
          </Row>
        </EdgeBorderFluid>

        <BlockImageTextHalf
          image={ImageRoyalCaninWeb}
          alt="Webová stránka Royal Canin"
          buttonText="Celá reference"
          buttonLink="/vyvoj/reference/royal-canin"
        >
          <LogoHeading imagePath={LogoRoyalCanin} imageAlt="logo Royal Canin">
            B2B aplikace ušetřila třetinu času
          </LogoHeading>

          <p>
            V Royal Canin probíhala všechna produktová komunikace s B2B partnery
            „ručně“. Díky naší automatizaci vidí všechny nové produkty téměř bez
            práce a v reálném čase.
          </p>
          <p>
            Zvýšili jsme tím počet vyřízených objednávek o 10 % a zákaznické
            podpoře ušetřili až 35 % času. Z naší aplikace Portail se stal
            nejlépe hodnocený projekt ve společnosti Royal Canin v zemích
            střední a severní Evropy za poslední dva roky.
          </p>
        </BlockImageTextHalf>

        <BlockImageTextHalf
          image={ImageDoktorKladivoWeb}
          alt="Webová stránka Doktor Kladivo"
          buttonText="Celá reference"
          buttonLink="/vyvoj/reference/doktor-kladivo"
          right
        >
          <LogoHeading
            imagePath={LogoDoktorKladivo}
            imageAlt="Logo Doktor Kladivo"
            href="/vyvoj/reference/doktor-kladivo"
          >
            E-shop pro více než <br />
            40 000 produktů
          </LogoHeading>

          <p>
            400 kategorií, 36 typových podstránek a víc než 12 různých výrobců -
            takový e-shop jsme vytvořili pro Doktora Kladivo.
          </p>
          <p>
            A nezůstalo jen u toho, naše spolupráce pokračuje. Společně jsme
            řešili napojení na Pohodu a mailingový nástroj Mailkit, víc než 12
            různých automatických importů od dodavatelů a mnoho dalšího.
          </p>
        </BlockImageTextHalf>

        <BlockImageTextHalf
          image={ImageSNBZWeb}
          alt="Webová stránka Snowboard Zezula"
          buttonText="Celá reference"
          buttonLink="/vyvoj/reference/snowboard-zezula"
        >
          <LogoHeading imagePath={LogoSZ} imageAlt="Logo Snowboard Zezula">
            Kompletní vývoj, marketing i správa
          </LogoHeading>

          <p>
            Se Snowboard Zezula spolupracujeme už {already(2007)}. Začali jsme
            jim pomáhat se správou sítě a údržbou e-shopu, na základě dobrých
            zkušeností nám svěřili celý vývoj. Vytvořili jsme pro ně jak nový
            e-shop, tak B2B platformu.
          </p>
          <p>
            Od roku 2010 máme na starosti i PPC kampaně a dnes pro klienta
            řešíme veškerý online marketing.
          </p>
        </BlockImageTextHalf>
      </Container>

      <ReferencesNext data={vyvojReferences} buttonLink="/vyvoj/reference" />

      <div id="formular">
        <ContactForm contact="sobol" />
      </div>
    </Layout>
  )
}

export default Vyvoj
